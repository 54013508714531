export const CREATE_PAGE_KEYS = {
  SELECT: "select",
  PUBLISH: "publish",
};

export const CREATE_PAGE_ENUM = {
  [CREATE_PAGE_KEYS.SELECT]: {
    title: "Select Template",
    key: CREATE_PAGE_KEYS.SELECT,
    routeAction: CREATE_PAGE_KEYS.SELECT,
  },
  [CREATE_PAGE_KEYS.PUBLISH]: {
    title: "Campaign Details",
    key: CREATE_PAGE_KEYS.PUBLISH,
    routeAction: CREATE_PAGE_KEYS.PUBLISH,
  },
};

export const SECONDARY_ACTIONS = {
  PREVIEW: "preview",
  DUPLICATE: "duplicate",
  EDIT: "edit",
};

export const CAMPAIGN_STEPS = {
  CAMPAIGN_DRAFT: 1,
  CAMPAIGN_ADDED: 3,
  CAMPAIGN_COMPLETED: 4,
  CAMPAIGN_PROCESSING: 7,
  CAMPAIGN_FAILED: 8,
};

export const WHATSAPP_CAMPAIGN_DESCRIPTIONS = {
  [CAMPAIGN_STEPS.CAMPAIGN_ADDED]:
    "Campaign has been added to the system for WhatsApp sending process and can take upto 5-10 minutes to change state to processing after trigger.",
  [CAMPAIGN_STEPS.CAMPAIGN_PROCESSING]:
    "Campaign is picked for collecting and sending messages and can remain in processing state upto 24 hours max.",
  [CAMPAIGN_STEPS.CAMPAIGN_COMPLETED]:
    "Campaign has sent all the messages from the system.",
  [CAMPAIGN_STEPS.CAMPAIGN_FAILED]:
    "Campaign sending process has failed due to some reason.",
};

export const parseTitle = (variableKey) =>
  variableKey
    .split("_")
    .map((k) => (k.length > 1 ? `${k[0].toUpperCase()}${k.slice(1)}` : k))
    .join(" ");

export const DELIMITERS = [
  [" *", " <b>"],
  ["* ", "</b> "],
  [" _", " <i>"],
  ["_ ", "</i> "],
  [" ~", " <strike>"],
  ["~ ", "</strike> "],
  ["{{", `<code title="message variable">`],
  ["}}", "</code>"],
];

export const renderWhatsappFormatWithVariables =
  (previewVariables = {}) =>
  (rawString = "") => {
    let returnString = rawString.replace(/  +/g, " ");

    DELIMITERS.forEach(([identifier, tag]) => {
      returnString = returnString.replaceAll(identifier, tag);
    });

    Object.entries(previewVariables).forEach(([k, v]) => {
      if (v)
        returnString = returnString.replaceAll(
          `<code title="message variable">${k}</code>`,
          `<code title="message variable">${v}</code>`
        );
    });

    return returnString;
  };

export const WHATSAPP_CAMPAIGN_STATUS = {
  DRAFT: 1,
  SCHEDULED: 2,
  ACTIVE: 3,
  COMPLETED: 4,
  PAUSED: 5,
  DELETED: 6,
  PROCESSING: 7,
};

export const INITIAL_FORM_DATA = {
  title: null,
  audience: [],
  template_uid: "",
  variable_values: {},
  offering_name: null,
  // Do not remove. Will be used later.
  // media_url: "",
  // media_type: "DOCUMENT",

  status: WHATSAPP_CAMPAIGN_STATUS.DRAFT,
};

export const HEADER_MEDIA_OPTIONS = {
  NONE: "1",
  IMG: "2",
  VID: "3",
  DOC: "4",
  TXT: "5",
};

export const ACCEPTED_FILE_TYPES = {
  QUICKTIME: "video/quicktime",
  MP4: "video/mp4",
  JPEG: "image/jpeg",
  PNG: "image/png",
  PDF: "application/pdf",
};

export const IMAGE_CROP_CONFIG = {
  ASPECT_RATIO: "1.91:1",
};

export const statusLabelMapping = {
  1: "Draft",
  2: "Pending",
  3: "Pending", //Submitted template also showing as pending on front end
  4: "Approved",
  5: "Rejected",
};

export const templateCategoryMapping = {
  1: "Transactional",
  2: "Marketing",
  3: "Otp",
  4: "Authentication",
  5: "Utility",
};

export const listingSpecificVariablesMapping = {
  offering_name: "offering_name",
  offering_link: "offering_link",
  offering_location: "offering_location",
};

export const commsSpecificVariablesMapping = {
  full_name: "full_name",
  email: "email",
  country: "country",
};

export const allPersoanlisationVariables = Object.keys(
  listingSpecificVariablesMapping
).concat(Object.keys(commsSpecificVariablesMapping));

export const offerTooltipTitle =
  "In the dropdown, you’ll only see offerings that have their location as either Google meet, Zoom or an online meeting link. This is because you have added the offering location variable in your message template";

export const campaignStepsData = {
  [CAMPAIGN_STEPS.CAMPAIGN_DRAFT]: {
    className: "campaignAdded",
    label: "Campaign Added",
  },
  [CAMPAIGN_STEPS.CAMPAIGN_PROCESSING]: {
    className: "campaignProcessing",
    label: "Campaign Processing",
  },
  [CAMPAIGN_STEPS.CAMPAIGN_ADDED]: {
    className: "campaignAdded",
    label: "Campaign Added",
  },
  [CAMPAIGN_STEPS.CAMPAIGN_FAILED]: {
    className: "campaignFailed",
    label: "Campaign Failed",
  },
  [CAMPAIGN_STEPS.CAMPAIGN_COMPLETED]: {
    className: "campaignCompleted",
    label: "Campaign Completed",
  },
};

export const STATUS = "Status";
export const action = "action";
export const preview = "preview";
export const edit = "edit";
export const segmentId = "segment_id";
export const campaign_uid = "campaign_uid";
export const whatsapp_white_label_data = "whatsapp_white_label_data";
export const ai_sensy_demo_video_url =
  "https://www.youtube.com/watch?v=G4Rwn53rqsk";
export const meta_url =
  "https://business.facebook.com/select/?next=https%3A%2F%2Fbusiness.facebook.com%2Fsettings%2Fsecurity&ext=1697033881&hash=AflL2xG_NZQYuLwzWq9f0TKjvnxR1RwTNLKigeUBdVbp1w";
export const edit_business_profile =
  "https://business.facebook.com/wa/manage/phone-numbers/";
export const facebook_manager_docs =
  "https://www.facebook.com/business/help/338047025165344#types";

export const whatsapp_whitelabelled_status_keys = {
  success: "success",
  error: "error",
  warning: "warning",
};

export const green_tick_application_steps = {
  GUIDE: 0,
  BASIC_DETAILS: 1,
  COMPANY_DETAILS: 2,
};

export const whitelabelled_whatsapp_status_constants = {
  PENDING: 1,
  APPROVED: 2, // When step 1 & step 2 is completed
  CONNECTED: 3, // when templates are also approved
  FAILED: 4,
  BLOCKED: 5,
};

export const exly_whatsapp_connect_status_consts = {
  NOT_APPLIED: 1,
  IN_PROGRESS: 2,
  CONNECTED: 3,
  FAILED: 4,
};

export const fb_business_manager_status_consts = {
  // step 2 (business manager profile)
  VERIFIED: 1,
  NOT_VERIFIED: 2,
  FAILED: 3,
  IN_REVIEW: 4,
  PENDING_SUBMISSION: 5,
};

export const green_tick_status_consts = {
  SUBMITTED: 1,
  ACCEPTED: 2,
  REJECTED: 3,
};

export const whatsapp_region_options = [
  { label: "Latin America", value: "Latin America" },
  { label: "North America", value: "North America" },
  { label: "Sub Saharan Africa", value: "Sub Saharan Africa" },
  {
    label: "Middle East and North Africa",
    value: "Middle East and North Africa",
  },
  { label: "Europe", value: "Europe" },
  { label: "CIS", value: "CIS" },
  { label: "Asia Pacific", value: "Asia Pacific" },
];

export const whatsapp_platform_choices_consts = {
  FACEBOOK: 1,
  FRESHCHAT: 2,
  AISENSY: 3,
};

export const green_tick_form_data = {
  email: { key: "email", label: "Email", placeholder: "Enter email" },
  country: {
    key: "country",
    label: "Country",
    placeholder: "Enter country",
  },
  region: { key: "region", label: "Region", placeholder: "Select one" },
  facebook_business_manager_id: {
    key: "facebook_business_manager_id",
    label: "Meta Business Manager ID that was used to integrate with Exly",
    placeholder: "Enter ID",
  },
  whatsapp_number: {
    key: "whatsapp_number",
    label: "WhatsApp number registered with Exly",
    placeholder: "Enter whatsapp number",
  },
  display_name: {
    key: "display_name",
    label: "Which display name should appear when messaging this account?",
    placeholder: "Enter display name",
  },
  company_details: {
    company_name: {
      key: "company_name",
      label: "Company Name",
      placeholder: "Enter company name",
    },
    company_website: {
      key: "company_website",
      label: "Company Website",
      placeholder: "Enter company website",
    },
    company_facebook_page: {
      key: "company_facebook_page",
      label: "Company's Facebook Page",
      placeholder: "Enter URL",
    },
    alternate_business_names: {
      key: "alternate_business_names",
      label:
        "If the business is known under any other name, please provide those names",
      placeholder: "Enter name(s)",
    },
    business_name_in_source_language: {
      key: "business_name_in_source_language",
      label:
        "If the business name is in a language other than English, please provide their business name in the source language",
      placeholder: "Enter business name in the source language",
    },
    market_reach_detail: {
      key: "market_reach_detail",
      label: "Please detail the company's market reach",
      placeholder: "Enter market reach",
    },
    product_and_services: {
      key: "product_and_services",
      label: "What are the company's products and services?",
      placeholder: "Enter prouct & services",
    },
    number_of_employees: {
      key: "number_of_employees",
      label: "How many employees does the company have?",
      placeholder: "Enter number of employees",
    },
    client_base_size: {
      key: "client_base_size",
      label: "How big is the company's client base?",
      placeholder: "Enter client base",
    },
    company_revenue: {
      key: "company_revenue",
      label: "What is the company's revenue?",
      placeholder: "Enter company’s revenue",
    },
    awards_details: {
      key: "awards_details",
      label: "Has the company won any awards? If yes, which ones?",
      placeholder: "Enter award details",
    },
    media_coverage_details: {
      key: "media_coverage_details",
      label:
        "Please share at least 3 links of media coverage about the company (articles in newspapers, websites, magazines, etc.)",
      placeholder: "Enter links",
    },
    official_business_account_approval_reason: {
      key: "official_business_account_approval_reason",
      label:
        "Why do you think the company is relevant enough to have the account approved as an Official Business Account?",
      placeholder: "Enter your answer",
    },
    other_details: {
      key: "other_details",
      label: "Any other relevant information",
      placeholder: "Enter your answer",
    },
  },
};
export const action_type = "action_type";
export const selected_switch_type = "selected_switch_type";
export const selected_comm_type = "selected_comm_type";
export const preselected_numbers = "preselected_numbers";
export const isCustomTemplateKey = "isCustomTemplate";

//default active step
export const DEFAULT_ACTIVE_STEP = 0;
export const DEFAULT_COMM_TIMING = 0;

export const MAX_RECIPIENTS_ALLOWED = 990;

export const form_data = {
  title: "title",
  variable_values: "variable_values",
  template_uid: "template_uid",
  audience: "audience",
  header: "header",
};

export const recipient_types = {
  segment: "segment",
  group: "group",
  individual: "individual",
};

export const WHATSAPP_BROADCASTS_LEARN_MORE_HREFS = {
  WHATSAPP_BROADCASTS:
    "https://support.exlyapp.com/support/solutions/articles/84000392243-creating-whatsapp-broadcasts-on-exly",
  WHATSAPP_BROADCASTS_VIDEO:
    "https://www.youtube-nocookie.com/embed/wpO28YyEp2g",
};
